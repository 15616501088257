<template>
  <div>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="cancel" width="37%" :footer="null" :centered="true">
      <FormList @getVal="getVal"  :isBtn="true" :fromList="fromList" ref="formListRef"></FormList>
      <div>
        <a-table  size="small" bordered :pagination="false" @change="changePage" :loading="searchLoading" :columns="columns" :data-source="dataList" :rowKey="(record, index) => { return index;}">
          <!-- 操作 -->
          <template #add="{ record }">
            <a @click="edit(record)" class="m-r1">编辑</a>
            <a-popconfirm
              title="你确定要删除吗"
              ok-text="确定"
              cancel-text="取消"
              @confirm="deleteAdd(record)"
            >
            <a>删除</a>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </a-modal>
    <a-modal :title="editTitle" v-model:visible="editVisible" @ok="editOk"  width="25%" :centered="true">
      <FormList @getVal="getVal"   :fromList="editFromList" ref="editRef"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FormList from '@/components/formList'
import { message } from 'ant-design-vue'; 
import { editInvoiceOrg } from '@/api/finance/finance.js'

const props = defineProps({
  visible:{
    type:Boolean,
    default:false
  },
  title:{
    type:String,
    default:''
  },
  fromList:{
    type:Array,
    default:[]
  },
  columns:{
    type:Array,
    default:[]
  },
  dataList:{
    type:Array,
    default:[]
  },
})
const emit = defineEmits(['update:visible','deleteAdd','getVal','editOk'])
const isSuccess = ref(false)
const editTitle = ref('')
const formListRef = ref(null)
const editVisible = ref(false)
const exp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
const editRef = ref(null)
const editOk = () => {
  if(isSuccess.value) return
 let monthQuota = editRef.value.fromOptions?.monthQuota
 if(!exp.test(monthQuota)){
  message.warning('开票额度格式有误')
    return
 }
 if(!monthQuota){
  message.warning('请填写发票额度')
    return
 }
 if(Number(monthQuota)<editItem.value.usedQuota){
  message.warning('开票额度应大于已用额度')
    return
 }
 isSuccess.value = true
 let obj = {
  id:editItem.value.id,
  orgId:editItem.value.orgId,
  orgName:editItem.value.orgName,
  monthQuota:Number(monthQuota)
 }
 editInvoiceOrg(obj).then( res => {
   if(res.code !== 10000) return
   editVisible.value = false
   message.success(res.msg)
   emit('editOk')
 }).finally( () => {
  isSuccess.value = false
 })
}
// 编辑
const editItem = ref({})
const edit = (item) => {
  editTitle.value =  item.orgName + '- 编辑'
  editItem.value = item
  editFromList.value[0].value = item.monthQuota
  editVisible.value = true
}
const cancel = () =>{
   emit('update:visible',false)
}
const empty = () => {
  formListRef.value.setValue()
}
const deleteAdd = (record) => {
  emit('deleteAdd',record)
}
const getVal = (val) => {
  if(!exp.test(val.lineOfInvoice)){
    message.warning('开票额度格式有误')
    return
  }
 emit('getVal',val)
}
const editFromList = ref([
  {
    label: '每月开票额度',
    name: 'monthQuota',
    type: 'input',
    placeholder: '填写',
    value: '',
    prop: 'monthQuota',
    width: '100%',
    unit:'元',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
])
defineExpose({
  empty
})


</script>

<style lang="less" scoped>

</style>