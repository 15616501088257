import { postBodyRequest, getRequest,putRequest } from '@/utils/axios'

//配置发票主体
export function  saveSubject(params) {
  return postBodyRequest('/trans/invoice/save/subject', params)
}
//获取发票主体分页
export function  subjectPage(params) {
  return postBodyRequest('/trans/invoice/subject/page', params)
}

//添加发票部门主体
export function  addInvoice(params) {
  return postBodyRequest('/trans/invoice/add/invoice-org', params)
}
//获取部门发票
export function  getInvoiceList(id) {
  return getRequest(`/trans/invoice/invoice-org/list?id=${id}` )
}
//删除部门发票
export function  deleteInvoice(id) {
  return getRequest(`/trans/invoice/delete-invoice-org?id=${id}`)
}

// 是否启用
export function  IsenableReq(id) {
  return getRequest(`/trans/invoice/subject/enable?id=${id}` )
}
// 是否设置为主要
export function  subjectMain(id) {
  return getRequest(`/trans/invoice/subject/main?id=${id}` )
}
//承运类型
export function  subjectCarrierType() {
  return getRequest(`/trans/invoice/subject-carrier-type` )
}

// 查询车辆发票明细
export function  vehicleInvoice(params) {
  return postBodyRequest('/trans/finance/bill-invoice/vehicle-invoice', params)
}
// 财务发票-对账
export function  billInvoiceAdd(params) {
  return postBodyRequest('/trans/finance/bill-invoice/add', params)
}
// 财务-发票分页
export function  billInvoicePage(params) {
  return postBodyRequest('/trans/finance/bill-invoice/page', params)
}
// 财务发票-详情
export function  billInvoiceInvoiceId(invoiceId) {
  return postBodyRequest(`/trans/finance/bill-invoice/${invoiceId}`)
}
// 财务发票-添加车辆
export function  billInvoiceAddVehicle(params) {
  return postBodyRequest('/trans/finance/bill-invoice/add-vehicle', params)
}
//默认发票
export function InvoiceMainDetail(id) {
  console.log(id);
  return getRequest(`/market/crm/customer/invoice/main-detail?customerId=${id}`)
}
//获取合同
export function  contractList(customerId) {
  return getRequest(`/market/crm/contract/${customerId}/list`)
}

// 财务发票-用于作废或冲红-详情
export function  InvoiceProvide(invoiceId) {
  return postBodyRequest(`/trans/finance/bill-invoice/${invoiceId}/provide-cancel`)
}
//删除财务发票
export function  InvoiceDelete(invoiceId) {
  return postBodyRequest(`/trans/finance/bill-invoice/${invoiceId}/delete`)
}
// 财务发票-删除明细
export function  invoiceDeleteInfo(invoiceId,orderInvoiceId) {
  return putRequest(`/trans/finance/bill-invoice/${invoiceId}/delete/${orderInvoiceId}`,)
}
//发票统计
export function  invoiceStatistic(params) {
  return postBodyRequest(`/anal/invoice/invoice-statistic`,params)
}
// 发票统计税率
export function taxRateStatistic(params) {
  return postBodyRequest(`/anal/invoice/tax-rate-statistic`,params)
}
// 发票部门统计税率
export function departStatistic(params) {
  return postBodyRequest(`/anal/invoice/depart-statistic`,params)
}
// 编辑发票部门主体
export function editInvoiceOrg(params) {
  return postBodyRequest(`/trans/invoice/edit/invoice-org`,params)
}
// 财务-发票分页
export function invoicePageList(params) {
  return postBodyRequest(`/anal/invoice/page`,params)
}
// 合计开票金额
export function totalInvoiceAmt(params) {
  return postBodyRequest(`/anal/invoice/total-invoice-amt`,params)
}
// 财务发票-合并账单
export function billInvoiceMerge(params) {
  return postBodyRequest(`/trans/finance/bill-invoice/merge`,params)
}