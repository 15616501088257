<template>
  <div>
      <div class="ant">
        <a-space>
          <a-popover overlayClassName="ant-hx-table-column-setting-overlay" placement="bottom" trigger="click" arrow-point-at-center :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode
              }
            ">
            <template #content>
              <div class="ant-hx-table-column-setting-list">
                <a-checkbox-group v-model:value="state.checkColumns" @change="changeCheckColumns">
                  <div v-for="column in state.columns" :key="column.dataIndex">
                    <a-checkbox :value="column.dataIndex">{{
                      column.title
                    }}</a-checkbox>
                  </div>
                </a-checkbox-group>
              </div>
            </template>
            <template #title>
              <div class="flex ju-between">
                <span>{{ $t('columnTitle') }}</span>
                <a @click="columnReset">{{ $t('columnReset') }}</a>
              </div>
            </template>
            <a-button>
              <ControlOutlined :rotate="90" />列展示
            </a-button>
          </a-popover>
          <a-button @click="refresh">
            <RedoOutlined :rotate="240" />刷新
          </a-button>
        </a-space>
      </div>
    </div>
</template>

<script setup>
import {reactive,onMounted } from 'vue';
import {RedoOutlined,ControlOutlined,CloseOutlined} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
const currentRoute = useRoute()
const props = defineProps({
  columns:{
    type:Array,
    default:[]
  }
})
const state = reactive({
  columns: props.columns,
  checkColumns: props.columns.map((column) => {
    return column.dataIndex;
  }),
});
const emit = defineEmits(['refresh','update:columns'])
//刷新
const refresh = () => {
 emit('refresh')
}
const changeCheckColumns = (checkItem) => {
  emit(
    "update:columns",
    state.columns.filter((column) => checkItem.includes(column.dataIndex))
  );
    let list = JSON.stringify(state.columns.filter(column => checkItem.includes(column.dataIndex)))
    window.localStorage.setItem(currentRoute.name, list)
};
// 重置
const columnReset = () => {
  state.checkColumns = state.columns.map((column) => {
    return column.dataIndex;
  });
  emit("update:columns", state.columns);
  window.localStorage.removeItem(currentRoute.name)
};
onMounted (()=>{
  if (window.localStorage.getItem(currentRoute.name)) {
        let list = JSON.parse(window.localStorage.getItem(currentRoute.name))
        emit("update:columns",list);
        state.checkColumns= list.map(column => { return column.dataIndex })
      }
})
</script>

<style lang="less" scoped>

  .ant {
    :deep(.ant-btn) {
      background-color: #f0f2f5;
      border: none;
    }

    :deep(.ant-btn:hover) {
      color: #fff;
      border-color: #172b4d;
      background-color: #172b4d;
    }
  }

</style>